/* eslint-disable no-promise-executor-return */
import { get, deleteRequest, post } from '@osrdata/app_core/dist/requests'
import { signal } from '@preact/signals-react'
import terms from 'assets/terms'
import { ToastLevel, ToastSignal } from 'components'
import { GedExport, PresignedUrl, Request } from 'types'
import { catchable } from 'utils'
import { getFolderName } from './files'

export const RequestSignal = signal<Request[]>([])
export const GedExportSignal = signal<GedExport>({})

export const deleteRequests = async (id: string) => {
  const [error] = await catchable(() => deleteRequest(`/miki/requests/${id}`), true)
  if (error) return
  ToastSignal.value = {
    severity: ToastLevel.SUCCESS,
    message: terms.Pages.Home.requests.deleted,
  }
  RequestSignal.value = RequestSignal.value.filter(request => request.id !== id)
}

export const getRequests = async () => {
  const [error, response] = await catchable(() => get<Request[]>('/miki/requests'), true)
  if (error) return
  RequestSignal.value = response
}

export const getRequest = async (id: string) => {
  const [error, response] = await catchable(() => get<Request>(`/miki/requests/${id}`), true)
  if (error) return

  RequestSignal.value = RequestSignal.value.map(request => (request.id === id ? response : request))
}

export const postRequest = async (files: File[]) => {
  const [error, response] = await catchable(() => post<Request>('/miki/requests', {
    name: getFolderName(files),
  }), true)
  if (error) return null
  return response
}

export const getPresignedUrl = async (request: Request, file: File, abortSignal: AbortSignal) => {
  const [error, response] = await catchable(() => post<PresignedUrl>(
    `/miki/requests/${request.id}/documents`,
    { document_path: file.name },
    { signal: abortSignal },
  ))
  if (error) return null
  return response
}

export const validateRequest = async (request: Request) => {
  await catchable(() => post(`/miki/requests/${request.id}/upload-done`, {}), true)
  await getRequests()
}

export const getExcel = (request: Request, getLast: boolean) => async () => {
  try {
    const response = await get(`/miki/requests/${request.id}/download${getLast ? '-last-' : '-'}ep`, null, null, {
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    })

    const blob = new Blob([response], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    })

    const link = document.createElement('a')
    const url = URL.createObjectURL(blob)
    link.href = url
    link.download = `${request.name}-${request.last_modified}-EP.xlsx`

    document.body.appendChild(link)
    link.click()

    document.body.removeChild(link)
    URL.revokeObjectURL(url)
  } catch (error) {
    ToastSignal.value = {
      severity: ToastLevel.ERROR,
      message: terms.Messages.Download.failed('Excel'),
    }
  }
}

export const uploadExcel = async (request: Request, file: File) => {
  const formData = new FormData()
  formData.append(`${request.name}-${request.last_modified}-EP.xlsx`, file)

  await new Promise(_ => setTimeout(_, 1000))

  const [error] = await catchable(
    () => post(`/miki/requests/${request.id}/upload-ep`, formData),
    true,
    terms.Messages.Upload.failedExcel,
  )

  if (error) {
    ToastSignal.value = {
      severity: ToastLevel.ERROR,
      message: terms.Messages.Upload.failedExcel,
    }
    return [error]
  }

  await getRequest(request.id)

  ToastSignal.value = {
    severity: ToastLevel.SUCCESS,
    message: terms.Messages.Upload.successExcel,
  }

  return [null]
}

export const getGedExport = async (request: Request, exportId: string) => {
  const [error, response] = await catchable(
    () => get<{status: string, progress: number, total: number}>(`/miki/requests/${request.id}/export/${exportId}`),
    true,
  )
  await getRequest(request.id)

  if (error || !response) {
    // remove request from GedExportSignal
    GedExportSignal.value = {
      ...GedExportSignal.value,
      [request.id]: undefined,
    }
  }

  GedExportSignal.value = {
    ...GedExportSignal.value,
    [request.id]: {
      id: exportId,
      ...response,
    },
  }
}

export const startGedExport = async (request: Request) => {
  const [postError, postResponse] = await catchable(
    () => post<{export_id: string}>(`/miki/requests/${request.id}/export`, {}),
    true,
  )

  if (postError || !postResponse?.export_id) return

  await getRequest(request.id)
  await getGedExport(request, postResponse.export_id)
}

export const getZipFiles = (request: Request) => async () => {
  const [error, response] = await catchable(
    () => get(`/miki/requests/${request.id}/report`, null, null, {
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/zip',
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    }),
    true,
  )

  if (error || !response) {
    ToastSignal.value = {
      severity: ToastLevel.ERROR,
      message: terms.Messages.Download.failed('ZIP'),
    }
    return
  }

  const blob = new Blob([response], {
    type: 'application/zip',
  })

  const link = document.createElement('a')
  const url = URL.createObjectURL(blob)
  link.href = url
  link.download = `${request.name}-${request.last_modified}.zip`

  document.body.appendChild(link)
  link.click()

  document.body.removeChild(link)
  URL.revokeObjectURL(url)
}
